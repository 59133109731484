export const FIRMWARE_REVIEWS = [
  {
    id: "nBk4hZZ0VGc",
    title: "Обзор кастомной прошивки",
  },
  {
    id: "NMXqDrFIVx8",
    title: "Инструкция по установке",
  },
];
