import { FC, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import FormContent from "./FormContent";
import Social from "./Social";
import { cn } from "@/lib/utils";

export enum BuyModalItemType {
  ASIC,
  GPU,
}

interface Props {
  type: BuyModalItemType;
  title: string;
  isTriggerInactive: boolean;
}

const BuyModal: FC<Props> = ({ type, title, isTriggerInactive }) => {
  const [open, setOpen] = useState<boolean>(false);

  const displayTitle = useMemo(() => title.replaceAll(" ", "\xa0"), [title]);

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger disabled={isTriggerInactive}>
        <div
          className={cn(
            "asicRowButton",
            isTriggerInactive && "asicRowButtonInactive",
          )}
        >
          <div className="asicRowButtonText">Купить</div>
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader className="text-center">
          <DialogTitle className="text-center">
            Заказать {type === BuyModalItemType.ASIC ? "асик" : "видеокарту"}{" "}
            <span className="font-semibold text-sky-700">{displayTitle}</span>
          </DialogTitle>
          <DialogDescription className="text-center">
            В ATLAS ANTMINER можно оформить заказ или получить консультацию по
            данной модели.
          </DialogDescription>
        </DialogHeader>
        <FormContent name={title} onClose={closeHandler} />
        <Separator />
        <Social />
      </DialogContent>
    </Dialog>
  );
};

export default BuyModal;
