import { cn } from "@/lib/utils";
import React from "react";

const BlockTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => {
  return (
    <h2
      ref={ref}
      className={cn("text-2xl font-semibold tracking-tight", className)}
      {...props}
    >
      {children}
    </h2>
  );
});
BlockTitle.displayName = "BlockTitle";

export { BlockTitle };
