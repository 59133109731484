const SET_POWER_COST = "SET_POWER_COST";
const SET_FILTER = "SET_FILTER";
const SET_FILTER_REVERSED = "SET_FILTER_REVERSED";
const SET_ADVERTISING = "SET_ADVERTISING";
const SET_CURRENT_ADVERTISING = "SET_CURRENT_ADVERTISING";

const defaultState = {
  cost: 0.04,
  filer: 4, // 1 - Hashrate, 2 - Power, 3 - Revenue 24h, 4 - Profit 24h, 5 - Price, 6 - Payback
  filterReversed: false,
  advertising: [],
  currentAdvertising: 0,
};

export default function minersReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_POWER_COST:
      return {
        ...state,
        cost: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filer: action.payload,
        filterReversed: false,
      };
    case SET_FILTER_REVERSED:
      return {
        ...state,
        filterReversed: action.payload,
      };
    case SET_ADVERTISING:
      return {
        ...state,
        advertising: action.payload,
      };
    case SET_CURRENT_ADVERTISING:
      return {
        ...state,
        currentAdvertising:
          state.advertising.length - 1 === state.currentAdvertising
            ? 0
            : state.currentAdvertising + 1,
      };
    default:
      return state;
  }
}

export const setPowerCost = (cost) => ({ type: SET_POWER_COST, payload: cost });

export const setFilter = (filter) => ({ type: SET_FILTER, payload: filter });

export const setFilterReversed = (revers) => ({
  type: SET_FILTER_REVERSED,
  payload: revers,
});

export const setAdvertising = (advertising) => ({
  type: SET_ADVERTISING,
  payload: advertising,
});

export const setCurrentAdvertising = () => ({
  type: SET_CURRENT_ADVERTISING,
});
