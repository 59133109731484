import { sendForm } from "../../actions/mails";
import { useNavigate } from "react-router-dom";
import pages from "../../settings/pages.json";
import { FC, useState } from "react";
import { Button } from "@/components/ui/button";
import { LoadingSpinner } from "@/components/common/loading-spinner";
import { Input } from "../ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "../ui/textarea";

const formSchema = z.object({
  phone: z.string().min(1, "Укажите, пожалуйста, Telegram или Email"),
  note: z.string(),
});

interface Props {
  name: string;
  onClose: () => void;
}

const FormContent: FC<Props> = ({ name, onClose }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone: "",
      note: "",
    },
  });

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setIsLoading(true);

      await sendForm(name, values.phone, values.note);

      navigate(pages.gratitude.url);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-2 w-full space-y-4"
      >
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder="Telegram / Email"
                  autoComplete="email"
                  type="text"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="note"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  placeholder="Коментарий"
                  className="min-h-20 resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex w-full">
          <Button
            disabled={isLoading}
            type="submit"
            className="mx-auto w-full sm:w-1/2"
          >
            {isLoading && <LoadingSpinner />}
            Отправить
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default FormContent;
