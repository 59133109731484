import { FC } from "react";

const LINKS = [
  {
    href: "viber://chat?number=380671387038",
    imgSrc: "/assets/img/viber-icon-black.svg",
    imgAlt: "Viber icon",
  },
  {
    href: "http://t.me/alfacore",
    imgSrc: "/assets/img/telegram-icon-black.svg",
    imgAlt: "Telegram icon",
  },
];

const Social: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-3">
      <p className="text-center text-sm">или напишите нам в вайбер/телеграм</p>
      <div className="flex gap-x-4">
        {LINKS.map((link, i) => (
          <a key={i} href={link.href} target="_blank" rel="noreferrer">
            <img src={link.imgSrc} alt={link.imgAlt} className="h-8 w-8" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Social;
