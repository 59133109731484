import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  miningTableGetPayback,
  miningTableGetProfit,
} from "../../helpers/miningTable.helper";
import { BuyModal } from "../buy-modal";
import { BuyModalItemType } from "../buy-modal/BuyModal";

import "./asicRow.css";

export default function AsicRow({ el }) {
  const currentMiners = useSelector((state) => state.miners.cost);
  const currentFilter = useSelector((state) => state.miners.filer);

  const profit = useMemo(
    () => miningTableGetProfit(el, currentMiners),
    [el, currentMiners],
  );

  return (
    <tr
      className={["asicRow", el.price < 0 ? "asicRowInactive" : null].join(" ")}
    >
      <td className="text-left">
        <div className="asicRowNameWrapper">
          <img className="asicRowImg" alt={"logo"} src={`${el.img}`} />
          <div className="asicRowName">{el.name}</div>
        </div>
      </td>
      <td className="text-center">{el.release}</td>
      <td
        className={[
          "text-right",
          currentFilter === 1 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        {el.hashrate}
      </td>
      <td
        className={[
          "text-right",
          currentFilter === 2 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        {el.power}
      </td>
      <td className="text-center">{el.algo}</td>
      <td
        className={[
          "text-right",
          currentFilter === 3 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        {el.revenue}
      </td>
      <td
        className={[
          "text-right",
          currentFilter === 4 ? "asicRowSelected" : null,
        ].join(" ")}
      >{`$${profit}`}</td>
      <td
        className={[
          "text-center",
          "asicRowPrice",
          currentFilter === 5 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        {el.price > 0 ? "$" + el.price : "Нет в наличии"}
      </td>
      <td
        className={[
          "text-left",
          "asicRowPrice",
          currentFilter === 6 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        {el.price > 0 &&
          (profit > 0
            ? miningTableGetPayback(el, currentMiners, null) + " Мес."
            : "Не окупается")}
      </td>
      <td className="text-left">
        <BuyModal
          type={BuyModalItemType.ASIC}
          title={el.name}
          isTriggerInactive={el.price < 0}
        />
      </td>
    </tr>
  );
}
