export enum FirmwareInstallerType {
  Windows = "Windows",
  MacOS = "macOS",
  LinuxAmd64 = "Linux-amd64",
  LinuxArm = "Linux-arm",
}

export const FIRMWARE_INSTALLERS: Array<{
  title: string;
  value: FirmwareInstallerType;
  href: string;
}> = [
  {
    title: "Windows",
    value: FirmwareInstallerType.Windows,
    href: "/uploads/HashcoreToolkit-1.0.2-Windows.zip",
  },
  {
    title: "MacOS",
    value: FirmwareInstallerType.MacOS,
    href: "/uploads/HashcoreToolkit-1.0.1-MacOS.dmg",
  },
  {
    title: "Linux x64",
    value: FirmwareInstallerType.LinuxAmd64,
    href: "/uploads/HashcoreToolkit-1.0.1-Linux_x64.zip",
  },
  {
    title: "Linux Arm",
    value: FirmwareInstallerType.LinuxArm,
    href: "/uploads/HashcoreToolkit-1.0.1-Linux_arm.zip",
  },
];
