import React from "react";
import { NavLink } from "react-router-dom";

import { cn } from "@/lib/utils";

const Link = React.forwardRef<
  React.ElementRef<typeof NavLink>,
  React.ComponentPropsWithoutRef<typeof NavLink>
>(({ className, ...props }, ref) => {
  return <NavLink ref={ref} className={cn("", className)} {...props} />;
});
Link.displayName = NavLink.displayName;

export { Link };
